import axios from "axios";

export default {
  login(email, senha) {
    return axios.post("/v1/auth/login", {
      username: email,
      password: senha
    });
  },
  alterarSenhaEsquecida(payload) {
    return axios.post("/v1/auth/alterar-senha", {
      email: payload.email,
      codigo: payload.codigo,
      novaSenha: payload.novaSenha
    });
  },
  refreshToken() {
    return axios.get("/v1/auth/refresh-token");
  },
  esqueciMinhaSenha(email) {
    return axios.post("/v1/auth/esqueci-minha-senha", { username: email });
  },
  alterarSenha(email, codigo, novaSenha) {
    return axios.post("/v1/auth/alterar-senha", {
      email: email,
      codigo: codigo,
      novaSenha: novaSenha
    });
  }
};
